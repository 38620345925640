import vikrant from '../images/team/vikrant.webp';
import rahul from '../images/team/rahul.webp';
import mridul from '../images/team/mridul.webp';
import hemant from '../images/team/hemant.webp';
import vaibhav from '../images/team/vaibhav.webp';
import anurag from '../images/team/anurag.png'
import jacques from '../images/team/jacques.webp';
import benny from '../images/team/benny.jpg';
import martyn from '../images/team/martyn.png';
import joachim from '../images/team/joachim.png';
import rasika from "../images/team/rasika.png";

export const teams = [
 
    // {
    //     id: 4,
    //     img: anurag,
    //     name: 'Anurag Kimothi',
    //     post: 'Partner',
    //     text: 'MBA, IIM Kolkata; IIT Delhi ',
    //     link: "https://www.linkedin.com/in/vaibhav-gupta-302b87/",
    //     about: `10+ years experience with US investment bank and specialty electronics
    //     retailer in UAE`
    // },
  
   

 
  
    {
        id: 1,
        img: vikrant,
        type: ['corporate-fundraising-advisory', 'transaction-advisory', 'capital-market-solutions'],
        name: 'Vikrant Singhi',
        home: true,
        post: 'Founding Partner',
        text: 'Ex PWC and Equirus Capital',
        link: "https://www.linkedin.com/in/vikrant-singhi-b5a0241/",
        about: 'He brings extensive expertise from his tenure at PwC and Equirus Capital, specializing in financial advisory and investment banking, driving strategic growth and innovation in his ventures.',
    },
    {
        id: 2,
        img: rahul,
        type: ['corporate-fundraising-advisory', 'transaction-advisory', 'capital-market-solutions', 'transaction-structuring'],
        name: 'Rahul Gupta',
        home: true,
        post: 'Founding Partner',
        text: 'Ex OYO (Head M&A-Tax), Deloitte, BMR &  Zamil (listed company in the KSA)',
        link: "https://www.linkedin.com/in/rahul-kumar-gupta-a831159/",
        about: 'He has a robust background including roles as Head of M&A (Tax) at OYO, and positions at Deloitte, BMR, and Zamil in KSA. His extensive experience spans mergers, acquisitions, and corporate tax strategies.'
    },
    {
        id: 3,
        img: hemant,
        home: true,
        type: ['debt-syndication-and-advisory'],
        name: 'Hemant Dhingra',
        post: 'Partner',
        text: 'Ex Pegasus Capital',
        link: "https://www.linkedin.com/in/hemant-dhingra-5466132a/",
        about: 'Excels in strategic investment and asset management. With extensive experience in private equity, he drives value creation and sustainable growth.'
    },
    {
        id: 4,
        img: vaibhav,
        home: true,
        type: ['debt-syndication-and-advisory'],
        name: 'Vaibhav Gupta',
        post: 'Partner',
        text: 'Ex Pegasus Capital',
        link: "https://www.linkedin.com/in/vaibhav-gupta-302b87/",
        about: 'He is a seasoned strategist with a keen eye for investment opportunities. His dynamic leadership and expertise in finance drive success'
    },
    {
        id: 5,
        img: benny,
        name: 'Benny Gala',
        type: [''],
        // home: true,
        post: 'Senior Advisor',
        text: 'Mayfair Equity Advisory (London)',
        link: "https://www.linkedin.com/in/hemant-dhingra-5466132a/",
        about: 'Benny Gala qualified as a chartered Accountant in 2001 and since then working in the field of Accounts and Finance.'
    },
    {
        id: 6,
        img: mridul,
        name: 'Mridul Shah',
        type: [''],
        post: 'Partner',
        text: 'Ex OYO, E&Y, Yes Bank, SBI, HDFC',
        link: "https://www.linkedin.com/in/mridulshah/",
        about: 'His diverse background spans top roles in finance, consulting, and hospitality, demonstrating strong leadership and strategic expertise.'
    },
    {
        id: 7,
        img: jacques,
        type: [''],
        name: 'Jacques Halpérin',
        post: 'Partner',
        text: 'Over 25 years of Investment Banking.',
        link: "https://www.linkedin.com/in/jacques-halp%C3%A9rin-8276428a/",
        about: 'Over 25 years of Investment Banking experience; CEO of various firms and currently acting as a Senior Advisor to Lazard Frères Gestion (Paris & Brussels). Exclusive tie-up with X-B4 for India inbound/ outbound M&A.',
    },
    // {
    //     id: 8,
    //     img: martyn,
    //     name: 'Martyn Ingram',
    //     post: 'Senior Advisor',
    //     text: 'Ex Pegasus Capital',
    //     link: "https://www.linkedin.com/in/vaibhav-gupta-302b87/",
    //     about: 'Martyn worked exclusively in the financial services industry for 40 years. He worked with an independent financial services firm.'
    // },
  
    // {
    //     id: 9,
    //     img: joachim,
    //     name: 'Joachim Von Halaz',
    //     post: 'Senior Advisor',
    //     text: 'Over 25 years of Investment Banking.',
    //     link: "https://www.linkedin.com/in/jacques-halp%C3%A9rin-8276428a/",
    //     about: 'Joachim has over 20 years of experience in reputation management, investor relations, and global media relations. He specialised in strategic cross border cooperation between banks.'
    // },
    {
        id: 10,
        img: rasika,
        type: ['capital-market-solutions'],
        name: 'Rasika Sawant',
        post: 'Head of Investor Relations',
        text: 'Experience in Investment Banking & Investor Relations.',
        link: "https://www.linkedin.com/in/rasikasawant/",
        about: 'Rasika has close to a decade of experience in investor relations & investment banking. She has worked in diverse sectors, including Pharma, Retail, Chemical, Capital Goods, Healthcare, Information Technology, Infrastructure, and FMCG.'
    },
    
]